.category-form__image{
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin-bottom: 16px;
    height: 100px;
    padding: 8px;
    display: flex;
    justify-content: center;
  & .category-form__image-wrapper{
      height: 100%;
      width: 90%;
      max-width: 150px;
      display: grid;
      border-radius: 5px;
      background-color: rgb(245, 245, 245);
      position: relative;
      place-content: center;
      padding: 4px;

      & img {
          max-height: 100%;
          max-width: 100%;
          margin: 0 auto;
      }

      & .category-form__trash-image{
          position: absolute;
          top: -5px;
          right: -5px;
      }
  }
}