.metadata-page{
    text-align: left;

    & .metadata__title{
        font-weight: bold;
    }

    & input {
        margin-bottom: 10px;
    }
}

