.select-search{
    position: relative;
}
.select-false-input{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.select-options{
    text-align: left;
    position: absolute;
    top: 105%;
    left: 0;
    background-color: #fff;
    width: 100%;
    box-shadow: 0px 0px 8px rgb(196, 196, 196);
    padding: .5rem 0;
    max-height: 300px;
    overflow-y: auto;
    border-radius: .3rem;
    z-index: 10;

    & li{
        padding: .5rem;
        cursor: pointer;
        &:hover{
            background-color: #f5f5f5;
        }
    }
}

.select-none{
    display: none;
}

.select-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: .5rem;
}

.tag{
    background-color: #f5f5f5;
    padding: 5px;
    border-radius: 8px;
}

.select-checkbox{
    display: flex;
    align-items: center;
    gap: 5px;

    & .checkbox {
        width: 20px;
        height: 20px;
    }
}