.product-item{
    border-radius: .5rem;
    margin-bottom: 1.3rem;
    text-align: left;

    &__name{
        font-size: 1.1rem;
        font-weight: 500;
    }
    &__description{
        font-size: .9rem;
        white-space: pre-line;
    }
    &__price{
        font-size: 1rem;
        font-weight: bold;
    }
    &__category-container{
        display: flex;
        margin-top: 5px;
    }
    &__category{
        background-color: var(--first-color);
        border-radius: 200px;
        padding: 4px 10px;
        font-size: 12px;
    }
}