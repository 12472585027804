.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);

    & h1{
      font-size: 1.2rem;
      font-weight: 500;
      color: rgb(63, 63, 63);
    }
  }
  
  .modal-main {
    position:fixed;
    background: white;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    overflow-y: scroll;
  }
  
  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }