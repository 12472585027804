.confirm-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 8;
}

.confirm-container {
   position: fixed;
    top: calc(50% - 150px);
    left: calc(50% - 150px);
    min-width: 250px;
    max-width: 300px;
    height: auto;
    text-align: center;
    background-color: #F2F2F2;
    border-radius: 25px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    z-index: 9;
    color: black;
    overflow: hidden;
}
.confirm-warning{
  margin-top: 20px;
  width: 30px;
  height: 30px;
  color: rgb(186, 64, 64);
}
.confirm-content {
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.confirm-msg {
  font-size: 1.2rem;
  font-weight: 500;
  border-bottom: 1px solid #E0E0E0;
  padding: 16px 20px;
}

.confirm-btn-container {
    display: grid;
    column-gap: 1px;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    background-color: #E0E0E0;
}

.confirm-btn{
    width: 100%;
    height: 100%;
    border: none;
    color: var(--first-color__alt);
    font-size: 1.2rem;
    cursor: pointer;
    padding: 16px;
    background-color: #F2F2F2;

    &--bold{
        font-weight: bold;
    }
}