body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*, *::before, *::after {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
}

input {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  border: none;
  background-color: #fff;
  transition: all 0.2s ease-in-out;
  outline: none;
  display: block;
  width: 100%;
}
button{
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  color: inherit;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] { -moz-appearance:textfield; }
ul, li{
  list-style: none;
  margin: 0;
  padding: 0;
}
a{
  text-decoration: none;
  color: inherit;
}
button{
  cursor: pointer;
}