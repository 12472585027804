.branding{
    padding: 1rem;
}
.branding__logo{
    width: 100%;
    max-width: 120px;
    margin: 0 auto;
    
    & img{
        width: 100%;
        height: auto;
    }
}
.branding__text{
   

    & h1{
        line-height: 2rem;
        margin-bottom: .3rem;
    }
    & p{
        color: gray;
        line-height: 1rem;
    }
}