$dark: #555555;
$height: .3em;
$rotate: .31em;

.btn4{
  font-size: 12px;
  width: 2em;
  height: 2em;
  transition-duration: 0.5s;
  display: grid;
  place-content: center;
  
  .icon {
    transition-duration: 0.5s;
    height: 1.2em;
    width: 2em;
    position: relative;
  
    &:before{
      transition-duration: 0.5s;
      position: absolute;
      width: 100%;
      height: $height;
      background-color: $dark;
      content: "";
      top: 0;
      border-radius: 5px;
    }
    
    &:after{
      transition-duration: 0.5s;
      position: absolute;
      width: 100%;
      height: $height;
      background-color: $dark;
      content: "";
      bottom: 0;
      border-radius: 5px;
    }
  }
    &.open {
      .icon {
        transition: 0.5s;
        &:before{
          transform: rotateZ(45deg) translate($rotate, $rotate);
        }
        &:after{
          transform: rotate(-45deg) translate($rotate, -$rotate);
        }
      }
    }
    &:hover {
      cursor: pointer;
    }
  }
  
