.input-container {
    width: 100%;
    text-align: left;
    margin-bottom: 10px;
  
    & span.error {
      font-size: 12px;
      padding: 3px;
      color: red;
      display: none;
    }
  }

  .input-label{
      display: block;
      margin-bottom: 5px;
      font-size: 14px;
      color: gray;
  }