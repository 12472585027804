.form {
    display: flex;
    align-items: center;

    &  form{
        background-color: white;
        border-radius: 10px;
        width: 100%;
    }
  }
  
  .button--form{
      width: 100%;
      height: 50px;
      padding: 10px;
      border: none;
      background-color: #181818;
      color: white;
      border-radius: 5px;
      font-weight: bold;
      font-size: 18px;
      cursor: pointer;
      margin-top: 15px;
      margin-bottom: 30px;
  }