:root{
  // Colores
  --first-color: #EEE1C3;
  --second-color: #C51716;
  --gray-color: #DCDCDC;
  --black-color: #4A4A4A;

  //padding
  --p4: 4rem;
  --p2: 2rem;
  --s1: 1rem;
  --s2: 2rem;
}

.App {
  text-align: center;
}
.App-link {
  color: #61dafb;
}

.wrapper{
  width: 100%;
  max-height: 1240px;
  padding: 2rem;
}

.container{
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: var(--p2) var(--p2);
}

.section-container{
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: var(--p4) var(--p2);
}

.small-column{
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}
.input{
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  border-radius: .4rem;
  padding: .5rem .5rem;
  border: 1px solid #ccc;
}
.input:focus{
  border-color: var(--first-color);
  outline: none;
}
.p13{
  padding: .5rem 3rem;
}
.p23{
  padding: 1rem 3rem;
}
.p03{
  padding: 0 3rem;
}
.mb1{
  margin-bottom: var(--s1);
}
.mb2{
  margin-bottom: var(--s2);
}
.fx{
  display: flex;
}
.fx2{
  display: flex;
  justify-content: center;
  align-items: center;
}
.fx23{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.fx24{
  display: flex;
  justify-content: space-between;
  align-items: center;
}