.navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    background-color: var(--first-color);
    border-bottom: 1px solid #e5e5e5;
    height: 2.5rem;
    position: relative;
    text-align: left;
    color: #3b3b3b;
}

.navbar-collapse{
    display: none;
    flex-direction: column;
    background-color: white;
    position: absolute;
    top: 2.5rem;
    left: 0;
    width: 100%;
    padding: 0.5rem 1rem;
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.15);

}
.collapse{
    display: flex;
}
.nav-item{
    padding: 0.3rem 0;
    color: rgb(110, 110, 110);
    cursor: pointer;
    width: 100%;
    & a.active{
        opacity: 0.9;
        color: #b8ad85;
    }
    &:hover {
       opacity: .5;
    }
    & .nav-link {
        display: block;
        width: 100%;
    }
}
