.category-item{
    margin-bottom: 2rem;
}
.category-item__image{
    width: 4.5rem;
    height: 4.5rem;
    border-radius: 6px;
    overflow: hidden;
    & img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.category-item__body{
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
