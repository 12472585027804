.icon{
    width: 1.2rem;
    height: 1.2rem;

    & img{
        height: 100%;
        width: auto;
    }
}
.btn-icon {
    width: 2.3rem;
    height: 2.3rem;
    padding: .6rem;
    margin-left: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
  }