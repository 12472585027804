.sort-action-container {
  display: flex;
  justify-content: flex-end;

  & button {
    color: rgb(87, 87, 87);
    padding: 1rem;
    transition: 0.3s;
    &:hover {
      color: rgb(138, 138, 138);
    }
  }
}

.sort-category {
  &__list {
    width: 100%;
    display: grid;
    row-gap: 10px;
  }

  &__item {
    padding: 10px;
    border: 1px solid rgb(228, 228, 228);
    border-radius: 10px;
    text-align: left;
    cursor: pointer;
    background-color: white;
    color: rgb(82, 82, 82);
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
  }
}
