.button{
    background: none;
    border: none;
    padding: 0.5em 1em;
    border-radius: .2rem;
    text-decoration: none;
    font-family: inherit;
    font-size: inherit;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    width: auto;
    max-width: 100%;
    display: inline-block;
    outline: none;
}

.button--primary{
    background: var(--first-color);
    color: #ffffff;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.button--secondary{
    border: 2px solid var(--gray-color);
    color: var(--black-color);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.button--small{
    padding: 0.2rem 0.6rem;
    font-size: .8rem;
    height: 2rem;
}
.button--wfull{
   width: 100%;
}
.button-clear{
    background: none;
    border: none;
    text-decoration: none;
    font-family: inherit;
    font-size: inherit;
    cursor: pointer;
    width: auto;
    font-weight: bold;
}
.button-inner{
    width: 100%;
}
.button-right-icon{
    margin-left: .5em;
}